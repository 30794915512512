import React from "react";
import Bubble from "@/common/UI/Bubble";
import Translator from "../hoc/Translator";

const JobPerksBubbles = ({ perks, limit }) => {
  return (
    <>
      {perks.map((perk, index) => (
        <Bubble color="1" key={perk.id ?? "Perk" + index}>
          <Translator>{perk.name}</Translator>
        </Bubble>
      ))}
    </>
  );
};

export default JobPerksBubbles;
