import React from "react";
import Link from "next/link";
import moment from "@/lib/localizedMoment";
import variables from "@/common/globalVariables";
import Bubble from "@/common/UI/Bubble";
import Icon from "@/common/UI/Icon";
import FavoriteButton from "../JobFavoriteButton/FavoriteButton";
import Card from "@/common/UI/Card";
import sanitize from "@/lib/html";
import PrompToRegister from "@/components/users/PrompToRegister";
import Translator from "@/components/hoc/Translator";
import { numberWithCommas } from "../JobCompensationBubbles";
import JobPerksBubbles from "../JobPerksBubbles";
import { currency } from "@/root/config";
import SendToFriendButton from "../sendJob/SendToFriendButton";
import { Grid, GridColumn } from "semantic-ui-react";

const styles = ` background-color: ${variables.lightColor};
                margin: 20px auto;
                transition: 100ms;
                animation-timing-function: ease-in;
                padding: 20px !important;
                border-radius: 15px;
                width: 100%;
                `;

const jobListItem = (props) => {
  const shortLocation = props.location.name;

  const jobUrl = formatJobUrl(props.title, props.location.name, props.id);

  return (
    <Card styles={styles}>
      <div className="JobListItemHeader">
        <div>
          <Link href="/jobs/[jid]" as={jobUrl} legacyBehavior>
            <a className="JobTitle">
              <Translator>{props.title}</Translator>
            </a>
          </Link>
          <p className="Location">
            <Icon icon="marker" size="sm" className="LocationIcon" />{" "}
            {shortLocation}
          </p>
        </div>

        <div className="JobListItemMainInfo">
          {props.showPayRate && (
            <Bubble color="1">
              {props.compensation > 0
                ? currency +
                  numberWithCommas(parseFloat(props.compensation).toFixed(2))
                : "DOE"}
            </Bubble>
          )}
          {props.showJobType && (
            <Bubble color="2">
              <Translator>{props.type}</Translator>
            </Bubble>
          )}
        </div>
      </div>
      <Link href="/jobs/[jid]" as={jobUrl} legacyBehavior>
        <a className="Content">
          <Translator>
            {sanitize(props.description, [])
              ["__html"].substring(0, 300)
              .replace("&amp;", "and")}
            ...
          </Translator>
        </a>
      </Link>

      <div className="JobListItemFooter">
        <div className="row wrap">
          <JobPerksBubbles perks={props.perks} />
        </div>
        <div className="row">
          <p className="PostDate">
            <Translator>{moment(props.date).fromNow()}</Translator>
          </p>

          <PrompToRegister>
            <div className="row">
              <div
                className="column"
                style={{
                  marginRight: "10px",
                }}
              >
                <SendToFriendButton jobId={props.id} />
              </div>
              <div className="column">
                <FavoriteButton
                  jobId={props.id}
                  count={props.favorites}
                  showFavoritesCount={props.showFavoritesCount}
                />
              </div>
            </div>
          </PrompToRegister>
        </div>
      </div>
      <style jsx>{`
        a {
          color: ${variables.darkColor};
          font-size: 1em;
          text-decoration: none;
        }

        .JobListItemHeader {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }

        .JobListItemHeader .JobTitle {
          font-weight: bold;
          text-decoration: none;
          color: ${variables.accentColor2};
          font-size: 1.2em;
        }

        .JobListItemHeader .JobTitle:hover {
          border-bottom: 1px solid red;
        }

        .Location {
          font-weight: bold;
          margin: 5px 0 0;
        }

        .Content {
          font-weight: normal;
          font-size: 1.1em;

          line-height: 1.4em;
          margin-bottom: 10px;
          display: block;
        }

        .LocationIcon {
          opacity: 0.4;
        }

        .LikeIcon {
          opacity: 0.4;
        }

        .JobListItemFooter {
          display: flex;
          flex-direction: column;
        }

        .JobListItemFooter .row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          margin-top: 5px;
          width: 100%;
        }

        .JobListItemFooter .row.wrap {
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .JobListItemFooter a {
          font-weight: bold;
          text-decoration: none;
        }

        .PostDate {
          font-size: 0.9em;
          font-weight: bold;
          color: ${variables.accentColor1};
        }

        @media (max-width: 720px) {
          .JobListItemHeader {
            margin-top: 10px;
          }

          .JobListItemMainInfo {
            position: absolute;
            right: 0;
            top: 0;
          }

          .JobListItemMainInfo :global(> span:nth-child(2)) {
            margin-left: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          .JobListItemMainInfo :global(> span:nth-child(1)) {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 0;
          }
        }
      `}</style>
    </Card>
  );
};

export default jobListItem;

export function formatJobUrl(title, location, id) {
  return `/jobs/${title.replace(/[\W_]+/g, "-")}-${location.replace(
    /[\W_]+/g,
    "-"
  )}-${id}`;
}
