import React, { useState, useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import axios from "axios";
import { Select } from "semantic-ui-react";
import HtmlRenderer from "@/common/UI/HtmlRenderer";
import applicationContext from "@/context/applicationContext";
import { systemLanguage } from "@/root/config";
import { debounce } from "lodash";

const Translator = ({ children }) => {
  const { language, setLanguage } = React.useContext(applicationContext);
  const [contentToDisplay, setContentToDisplay] = useState(children);

  useEffect(() => {
    if (!!localStorage && localStorage["k-jobboard-system-custom-lang"]) {
      setLanguage(localStorage["k-jobboard-system-custom-lang"]);
    } else {
      setLanguage(language);
    }
  }, []);

  // useEffect(() => {
  //   const apiUrl = `https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&To=${language}`;
  //   const maxRetries = 5;

  //   const makeTranslateRequest = async () => {
  //     for (let retryCount = 1; retryCount <= maxRetries; retryCount++) {
  //       try {
  //         const response = await axios.post("/api/translate", {
  //           url: apiUrl,
  //           data: [{ text: renderToStaticMarkup(children) }],
  //         });

  //         setContentToDisplay(
  //           <HtmlRenderer html={response.data.data[0].translations[0].text} />
  //         );
  //         return;
  //       } catch (error) {
  //         const retryDelay = Math.pow(2, retryCount) * 1000;
  //         await new Promise((resolve) => setTimeout(resolve, retryDelay));
  //       }
  //     }

  //     console.error(
  //       "Max retry attempts reached. Unable to complete the translation."
  //     );
  //   };

  //   const debouncedTranslateRequest = debounce(() => {
  //     makeTranslateRequest();
  //   }, 200); // Adjust the debounce delay as needed

  //   if (language !== systemLanguage) {
  //     debouncedTranslateRequest();
  //   } else {
  //     setContentToDisplay(children);
  //   }

  //   // Cleanup debounce on component unmount
  //   return () => debouncedTranslateRequest.cancel();
  // }, [language]);

  return contentToDisplay;
};
export const ListOfLanguages = () => {
  const [loading, setLoading] = useState(true);
  const [languages, setLanguages] = useState(null);
  const { language, setLanguage } = React.useContext(applicationContext);

  useEffect(() => {
    axios
      .get(
        "/api/translate?url=https://api.cognitive.microsofttranslator.com/languages?api-version=3.0"
      )
      .then((res) => {
        const fetchedLanguages = res.data.data.translation;
        const options = Object.keys(fetchedLanguages).map(
          (language, index) => ({
            key: language + index,
            text: fetchedLanguages[language].name,
            value: language,
          })
        );
        options.sort((a, b) => {
          if (a.text < b.text) {
            return -1;
          }
          if (a.text > b.text) {
            return 1;
          }
          return 0;
        });
        setLanguages(options);
        setLoading(false);
      });
  }, []);

  return loading ? (
    <p>Loading</p>
  ) : (
    <Select
      className="ListOfLanguages"
      options={languages}
      defaultValue={language}
      onChange={(e, data) => {
        setLanguage(data.value);
        localStorage.setItem("k-jobboard-system-custom-lang", data.value);
      }}
    />
  );
};

export default Translator;
